import React, { Component, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { getUser, getArazAPIUrl, getSLIM4APIUrl } from "./utils/Common";
import { toast } from "react-toastify";
import { Buffer } from "buffer";

// window.Buffer = window.Buffer || require("buffer").Buffer;

/**
 * Calculate number days between two Date
 * @param {Date} date1
 * @param {Date} date2
 * @returns
 */
const daysBetween = function (date1, date2) {
  if (!date2) return false;
  //Get 1 day in milliseconds
  var one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = new Date(date2).getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date1_ms - date2_ms;

  // Convert back to days and return
  return Math.round(difference_ms / one_day);
};

function getValueBeforeDashboardFromURL() {
  const url = window.location.href;
  const parts = url.split("/");
  const index = parts.indexOf("dashboard");
  if (index > 0) {
    return parts[index - 1];
  }
  return null; // Return null if "dashboard" is not found or if it's the first part
}

// console.log(paramsValue);

const Context = React.createContext();
class ContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiUrl: getSLIM4APIUrl(),
      arazApiUrl: getArazAPIUrl(),
      trackId: localStorage.getItem("track_id"),
      track_id: 0,
      hof_its: "",
      countries: [],
      cities: [],
      ptay_grd_country_list: [],
      ptay_10th_country_list: [],
      ptay_12th_country_list: [],
      step: 0,
      family_monthly_income: 0,
      isLoadedPI: true,
      isLoadedAC: true,
      isLoadedFS: true,
      isLoadedAP: true,
      isLoadedAppChat: true,
      redirectToDashboard: false,
      user_tanzeem_id: 0,
      requiredFiles: [],
      isLoadedDocs: true,
      redirectForNotAuthorized: false,
      personalDetails: [
        {
          its_id: 0,
          applicant_name: "",
          index: Math.random(),
          application_id: null,
          name: "",
          hof_its: "",
          hof_its_approved: 0,
          email_approved: 0,
          email: "",
          contact_no: "",
          contact_no_approved: 0,
          address: "",
          address_approved: 0,
          cheque_full_name: "",
          cheque_full_name_approved: 0,
        },
      ],
      personalDetailsErrors: {},
      preEduFinancial: [],
      familyMember: [],
      lastThreeYearEdu: [
        {
          institute_name: "",
          instruction_medium: "1",
          address: "",
          board: "",
          course_name: "",
          edu_type: "1",
          passing_year: "0",
          percentage: "0",
          preferred_subject: "",
          preferred_subject_percentage: "0",
          country: "",
          city: "",
        },
        {
          institute_name: "",
          instruction_medium: "2",
          address: "",
          board: "",
          course_name: "",
          edu_type: "2",
          passing_year: "0",
          percentage: "0",
          preferred_subject: "",
          preferred_subject_percentage: "0",
          country: "",
          city: "",
        },
        {
          institute_name: "",
          instruction_medium: "",
          address: "",
          board: "",
          course_name: "",
          edu_type: "3",
          passing_year: "0",
          percentage: "0",
          preferred_subject: "",
          preferred_subject_percentage: "0",
          country: "",
          city: "",
        },
      ],
      // This is depricated not with SLIM4 API, use lastThreeYearEdu instead
      eduDetails: [
        {
          index: Math.random(),
          se_school_name: "",
          se_school_name_approved: 0,
          se_instruction_medium: "",
          se_instruction_medium_approved: 0,
          ptay_10th_country: "",
          ptay_10th_city: "",
          se_address_approved: 0,
          se_board: "",
          se_board_approved: 0,
          hse_school_name: "",
          hse_school_name_approved: 0,
          hse_instruction_medium: "",
          hse_instruction_approved: 0,
          hse_address: "",
          hse_address_approved: 0,
          hse_board: "",
          hse_board_approved: 0,
          ptay_10th_passing_year: "",
          ptay_10th_passing_year_approved: 0,
          ptay_10th_percentage: "",
          ptay_10th_percentage_approved: 0,
          ptay_10th_preferred_subject: "",
          ptay_10th_preferred_subject_approved: 0,
          ptay_10th_percentage_preferred_subject: "",
          ptay_10th_percentage_preferred_subject_approved: 0,
          ptay_12th_passing_year: "",
          ptay_12th_passing_year_approved: 0,
          ptay_12th_percentage: "",
          ptay_12th_percentage_approved: 0,
          ptay_12th_preferred_subject: "",
          ptay_12th_country: "",
          ptay_12th_city: "",
          ptay_12th_preferred_subject_approved: 0,
          ptay_12th_percentage_preferred_subject: "",
          ptay_12th_percentage_preferred_subject_approved: 0,
          ptay_grd_passing_year: "",
          ptay_grd_passing_year_approved: 0,
          ptay_grd_percentage: "",
          ptay_grd_percentage_approved: 0,
          ptay_grd_preferred_subject: "",
          ptay_grd_preferred_subject_approved: 0,
          ptay_grd_percentage_preferred_subject: "",
          ptay_grd_percentage_preferred_subject_approved: 0,
          ptay_grd_country: "",
          ptay_grd_country_approved: 0,
          ptay_grd_city: "",
          ptay_grd_city_approved: 0,
          grd_school_name: "",
          grd_course_name: "",
          grd_address: "",
        },
      ],
      entranceExam: [],
      achivementsSchoolCollege: [],
      courseDetails: [
        {
          cd_course_name: "",
          cd_course_name_approved: 0,
          cd_career_interest: "",
          cd_career_interest_approved: 0,
          cd_course_duration: "",
          cd_course_duration_approved: 0,
          cf_total_personal_funds: "",
          cf_total_personal_funds_approved: 0,
        },
      ],
      feesStructure: [
        {
          index: Math.random(),
          fs_college_name: "",
          fs_college_name_approved: 0,
          fs_institute_country: "",
          fs_institute_country_approved: 0,
          fs_institute_city: "",
          fs_institute_city_approved: 0,
          fs_course_start_month: "",
          fs_course_start_month_approved: 0,
          fs_course_start_year: "",
          fs_course_start_year_approved: 0,
          fs_accommodation: "",
          fs_accommodation_approved: 0,
          fs_1st_y_tution_fees: "",
          fs_1st_y_tution_fees_approved: 0,
          fs_2st_y_tution_fees: "",
          fs_2st_y_tution_fees_approved: 0,
          fs_3rd_y_tution_fees: "",
          fs_3rd_y_tution_fees_approved: 0,
          fs_4th_y_tution_fees: "",
          fs_4th_y_tution_fees_approved: 0,
          fs_5th_y_tution_fees: "",
          fs_5th_y_tution_fees_approved: 0,
          fs_hostel_fees: "",
          fs_hostel_fees_approved: 0,
          fs_b_s_expenses_fees: "",
          fs_b_s_expenses_fees_approved: 0,
          fs_travel_expenses_fees: "",
          fs_travel_expenses_fees_approved: 0,
          fs_other_expenses_fees: "",
          fs_other_expenses_fees_approved: 0,
          fs_edu_total_cost: "",
          fs_edu_total_cost_approved: 0,
        },
      ],
      arazPreviewInfo: [],
      previewArazUserInfo: [],
      submittedPersonalDetails: [],
      submittedPreEduFinancial: [],
      submittedFamilyMember: [],
      submittedEduDetails: [],
      submittedEntranceExam: [
        {
          entrance_exam_name: "",
          entrance_exam_year: "",
        },
      ],
      submittedAchivementsSchoolCollege: [],
      submittedArazLoader: true,
      fs_edu_total_cost: 0,
      tanzeemNewArazList: [],
      isLoadedTNA: true,
      applicationInfo: [],
      tanzeemNewArazHistoryList: [],
      isLoadedTNAH: true,
      arazListAsRoleWise: [],
      arazListTab: 1,
      isLoadedALR: true,
      docsApprove: [],
      countFiles: 0,
      chatHistory: [],
      appStatusHistory: [],
      isLoadedAppStatus: true,
      appList: [],
      isLoadedAppList: true,
      appStatusList: [],
      isLoadedASL: true,
      isLoadedAdminLoading: true,
      isLoadedCounList: true,
      counsellorList: [],
      adminList: [],
      isLoadedTrusteeList: true,
      trusteeList: [],
      isLoadedETrusteeList: true,
      eTrusteeList: [],
      appCoordinators: [],
      amilSahebComments: "",
      // Increment it every time new request is made and decrement on resolved/reject
      // laoder will be visibel when newArazLoader > 0
      newArazLoader: 0,
      cooDashBoardLoading: false,
      isTrusteeListAsRoleWiseLoading: true,
      trusteeListAsRoleWise: [],
      trusteeApplicationCountsList: [],
      isTrusteeApplicationCountsLoading: true,
      execTrusteeApprovalRoleWiseList: [],
      trusteeApprovalApprovalList: [],
      trusteeApprovalSecondApprovalList: [],
      isExecTrusteeApprovalRoleWiseLoading: true,
      isExecTrusteeApplicationCountsLoading: true,
      execTrusteeApplicationCountsList: [],
      execTrusteeApplicationInfo: [],
      search_by_name: "",
      searchLoading: false,
      counselorApprovalList: [],
      isCounselorApprovalCountsLoading: true,
      counselorListAsRoleWise: [],
      isCounselorListAsRoleWiseLoading: true,
      counselorApplicationCountsList: [],
      counsellorApplicationInfo: [],
      showListWise: false,
      redirectToReferrer: false,
      role: 0,
      adminStatusList: [],
      coList: [],
    };
  }

  setArazPreviewInfo = (value) => this.setState({ arazPreviewInfo: value });

  setStates = (name, value) => {
    let personalDetails = [...this.state.personalDetails];
    personalDetails[0] = { ...personalDetails[0], [name]: value };
    this.setState({ personalDetails });
  };
  setShowListWise = (value) => this.setState({ showListWise: value });
  setStateByNameAndValue = (name, value, arrayName) => {
    if (arrayName === "personalDetails") {
      let personalDetails = [...this.state.personalDetails];
      personalDetails[0] = { ...personalDetails[0], [name]: value };
      this.setState({ personalDetails });
    }
  };

  setApplicationInfo = (data) => {
    this.setState({ applicationInfo: data });
  };

  setPersonalDetailsErrors = (key, value) => {
    this.setState({
      personalDetailsErrors: {
        ...this.state.personalDetailsErrors,
        [key]: value,
      },
    });
  };

  setPersonalDetails = (e) => {
    const { name, value } = e.target;
    let personalDetails = [...this.state.personalDetails];
    personalDetails[0] = { ...personalDetails[0], [name]: value };
    this.setState({ personalDetails });
    this.setState({
      personalDetailsError: {
        ...this.state.personalDetailsErrors,
        [name]: false,
      },
    });
  };
  setApplicationCheckBox = (e, arrayName) => {
    const { name } = e.target;
    if (arrayName === "personalDetails") {
      let personalDetails = [...this.state.personalDetails];
      if (this.state.personalDetails[0][name]) {
        personalDetails[0] = { ...personalDetails[0], [name]: 0 };
      } else {
        personalDetails[0] = { ...personalDetails[0], [name]: 1 };
      }
      this.setState({ personalDetails });
    } else if (arrayName === "preEduFinancial") {
      let preEduFinancial = [...this.state.preEduFinancial];
      if (this.state.preEduFinancial[e.target.dataset.id][name]) {
        preEduFinancial[e.target.dataset.id] = {
          ...preEduFinancial[e.target.dataset.id],
          [name]: 0,
        };
      } else {
        preEduFinancial[e.target.dataset.id] = {
          ...preEduFinancial[e.target.dataset.id],
          [name]: 1,
        };
      }
      this.setState({ preEduFinancial });
    } else if (arrayName === "familyMemberDetails") {
      let familyMember = [...this.state.familyMember];
      if (this.state.familyMember[0][name]) {
        familyMember[0] = { ...familyMember[0], [name]: 0 };
      } else {
        familyMember[0] = { ...familyMember[0], [name]: 1 };
      }
      this.setState({ familyMember });
    } else if (arrayName === "courseDetails") {
      let courseDetails = [...this.state.courseDetails];
      if (this.state.courseDetails[0][name]) {
        courseDetails[0] = { ...courseDetails[0], [name]: 0 };
      } else {
        courseDetails[0] = { ...courseDetails[0], [name]: 1 };
      }
      this.setState({ courseDetails });
    } else if (arrayName === "feesStructure") {
      let feesStructure = [...this.state.feesStructure];
      if (this.state.feesStructure[0][name]) {
        feesStructure[0] = { ...feesStructure[0], [name]: 0 };
      } else {
        feesStructure[0] = { ...feesStructure[0], [name]: 1 };
      }
      this.setState({ feesStructure });
    } else if (arrayName === "achivementsSchoolCollege") {
      let achivementsSchoolCollege = [...this.state.achivementsSchoolCollege];
      if (this.state.achivementsSchoolCollege[e.target.dataset.id][name]) {
        achivementsSchoolCollege[e.target.dataset.id] = {
          ...achivementsSchoolCollege[e.target.dataset.id],
          [name]: 0,
        };
      } else {
        achivementsSchoolCollege[e.target.dataset.id] = {
          ...achivementsSchoolCollege[e.target.dataset.id],
          [name]: 1,
        };
      }
      this.setState({ achivementsSchoolCollege });
    } else if (arrayName === "entranceExam") {
      let entranceExam = [...this.state.entranceExam];
      if (this.state.entranceExam[e.target.dataset.id][name]) {
        entranceExam[e.target.dataset.id] = {
          ...entranceExam[e.target.dataset.id],
          [name]: 0,
        };
      } else {
        entranceExam[e.target.dataset.id] = {
          ...entranceExam[e.target.dataset.id],
          [name]: 1,
        };
      }
      this.setState({ entranceExam });
    } else if (arrayName === "eduDetails") {
      let eduDetails = [...this.state.eduDetails];
      if (this.state.eduDetails[0][name]) {
        eduDetails[0] = { ...eduDetails[0], [name]: 0 };
      } else {
        eduDetails[0] = { ...eduDetails[0], [name]: 1 };
      }
      this.setState({ eduDetails });
    }
  };
  setStep = (step) => {
    this.setState({ step });
  };
  setApplicationDocsCheckBox = (e, propsName) => {
    this.setState({
      [propsName]:
        e.target.type === "checkbox" ? e.target.checked : +e.target.value,
    });
  };
  setApplicationDocumentCheckBox = (e, propsName) => {
    let docsApprove = [...this.state.docsApprove];
    docsApprove[e.target.dataset.id] = {
      ...docsApprove[e.target.dataset.id],
      [propsName]: e.target.checked,
    };
    this.setState({ docsApprove });
  };
  setPreEduFinancial = (e) => {
    const { name, value } = e.target;
    let preEduFinancial = [...this.state.preEduFinancial];
    preEduFinancial[e.target.dataset.id] = {
      ...preEduFinancial[e.target.dataset.id],
      [name]: value,
    };
    this.setState({ preEduFinancial });
  };

  setFamilyMember = (e) => {
    const { name, value } = e.target;
    const id = e.target.dataset.id;
    const errorKey = `${name}_error`;
    this.setState((prevState) => {
      let familyMember = [...prevState.familyMember];
      familyMember[id] = {
        ...familyMember[id],
        [name]: value,
        [errorKey]: false,
      };
      return { familyMember };
    });
  };

  setEduDetails = (e) => {
    const { name, value } = e.target;
    const errorField = `${name}_error`;
    this.setState((prevState) => {
      let eduDetails = [...prevState.eduDetails];

      eduDetails[0] = { ...eduDetails[0], [name]: value, [errorField]: false };
      return {
        eduDetails,
      };
    });

    // this.setState({ eduDetails });
  };
  setFeesStructure = (e) => {
    const { name, value } = e.target;
    const clearError = name.indexOf("_error") === -1;
    const errorFieldName = `${name}_error`;

    this.setState(
      (prevState) => {
        let feesStructure = [...prevState.feesStructure];
        if (clearError)
          feesStructure[0] = {
            ...feesStructure[0],
            [name]: value,
            [errorFieldName]: false,
          };
        else feesStructure[0] = { ...feesStructure[0], [name]: value };

        return { ...prevState, feesStructure };
      },
      () => this.getTotalCostOfEducation(this.state.feesStructure[0])
    );
  };
  getTotalCostOfEducation = (feesStructureData) => {
    const fields = [
      "fs_1st_y_tution_fees",
      "fs_2st_y_tution_fees",
      "fs_3rd_y_tution_fees",
      "fs_4th_y_tution_fees",
      "fs_5th_y_tution_fees",
      "fs_hostel_fees",
      "fs_b_s_expenses_fees",
      "fs_travel_expenses_fees",
      "fs_other_expenses_fees",
    ];

    let totalExp = 0;

    fields.forEach((field) => {
      if (feesStructureData[field]) {
        totalExp += Number(feesStructureData[field]);
      }
    });

    this.setState({ fs_edu_total_cost: totalExp });
  };

  /**
   * fetch latest entrace exam details list, and store it in this.state
   */
  getEntranceExamsDetails = async (application_id) => {
    const res = await fetch(
      `${getSLIM4APIUrl()}user/entranceExamsDetails/${application_id}`,
      {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      }
    );
    if (res.status === 204) {
      this.setState({ entranceExam: [] });
    } else {
      const json = await res.json();
      if (Array.isArray(json)) {
        this.setState({ entranceExam: json });
      }
    }
  };
  setCourseDetails = (e) => {
    const { name, value } = e.target;
    let courseDetails = [...this.state.courseDetails];
    courseDetails[0] = { ...courseDetails[0], [name]: value };
    this.setState({ courseDetails });
  };
  setEntranceExam = (e) => {
    const { name, value } = e.target;
    let entranceExam = [...this.state.entranceExam];
    entranceExam[e.target.dataset.id] = {
      ...entranceExam[e.target.dataset.id],
      [name]: value,
    };
    this.setState({ entranceExam });
  };
  setAchivementsSchoolCollege = (e) => {
    const { name, value } = e.target;
    let achivementsSchoolCollege = [...this.state.achivementsSchoolCollege];
    achivementsSchoolCollege[e.target.dataset.id] = {
      ...achivementsSchoolCollege[e.target.dataset.id],
      [name]: value,
    };
    this.setState({ achivementsSchoolCollege });
  };
  pushPreEduFinancial = (data) => {
    this.setState((prevState) => ({
      preEduFinancial: [...prevState.preEduFinancial, data],
    }));
  };
  addEduFinancialField = (e) => {
    this.setState((prevState) => ({
      preEduFinancial: [
        ...prevState.preEduFinancial,
        {
          index: Math.random(),
          pef_date: "",
          pef_date_approved: 0,
          pef_organization: "",
          pef_organization_approved: 0,
          pef_amount: "",
          pef_amount_approved: 0,
          pef_type: "",
          pef_type_approved: 0,
        },
      ],
    }));
  };
  addEntanceExamField = (e) => {
    this.setState((prevState) => ({
      entranceExam: [
        ...prevState.entranceExam,
        {
          index: Math.random(),
          entrance_exam_name: "",
          entrance_exam_year: "",
        },
      ],
    }));
  };
  addAchivementsSchoolCollegeField = (e) => {
    this.setState((prevState) => ({
      achivementsSchoolCollege: [
        ...prevState.achivementsSchoolCollege,
        {
          index: Math.random(),
          achivement_college: "",
          achivement_college_particulars: "",
        },
      ],
    }));
  };

  getAchivementSchoolCollege = async (application_id) => {
    const res = await fetch(
      `${getSLIM4APIUrl()}user/achivementsDetails/${application_id}`,
      {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      }
    );
    if (res.status === 204) {
      this.setState({ achivementsSchoolCollege: [] });
    } else {
      const json = await res.json();
      this.setState((prevState) => ({
        achivementsSchoolCollege: json,
      }));
    }
  };

  addFeesStructureField = (e) => {
    this.setState((prevState) => ({
      feesStructure: [
        ...prevState.feesStructure,
        {
          index: Math.random(),
          name: "",
          author: "",
          type: "",
          dateOfPublish: "",
          price: "",
        },
      ],
    }));
  };
  deleteEntanceExamField = (record) => {
    this.setState({
      entranceExam: this.state.entranceExam.filter((r) => r !== record),
    });
  };

  deleteAchivementsSchoolCollegeField = (record) => {
    this.setState({
      achivementsSchoolCollege: this.state.achivementsSchoolCollege.filter(
        (r) => r !== record
      ),
    });
  };
  deleteFeesStructureField(record) {
    this.setState({
      feesStructure: this.state.feesStructure.filter((r) => r !== record),
    });
  }
  deleteEduFinancialField(record) {
    this.setState({
      preEduFinancial: this.state.preEduFinancial.filter((r) => r !== record),
    });
  }
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  getApplicationDetails = (trackId, role, application_id) => {
    var url = this.state.apiUrl;
    this.addNewArazLoader();
    axios
      .get(getSLIM4APIUrl() + "user/applicationDetails/" + application_id, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          this.setState({
            personalDetails: [{ ...data, trackId: trackId }],
            isLoadedPI: false,
            familyMember: data.familyMembers,
          });
          localStorage.setItem("marhala", data.marhala);
          /*this.setStateByNameAndValue('hof_its', data.aplication_data.hof_its, 'personalDetails');
			this.setStateByNameAndValue('name', data.aplication_data.name, 'personalDetails');
			this.setStateByNameAndValue('email', data.aplication_data.email, 'personalDetails');
			this.setStateByNameAndValue('address', data.aplication_data.address, 'personalDetails');
			this.setStateByNameAndValue('contact_no', data.aplication_data.contact_no, 'personalDetails');
			this.setStateByNameAndValue('cheque_full_name', data.aplication_data.cheque_full_name, 'personalDetails');
			*/
          this.setStep(data.step);
          this.getFinancialDetails(trackId, role, application_id);
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
      .catch((error) => {
        this.setState({
          isLoaded: true,
        });
      })
      .finally(() => this.removeNewArazLoader());
  };
  getFinancialDetails = (trackId, role, application_id) => {
    var url = getSLIM4APIUrl();
    axios
      .get(url + `user/financialHelpHistory/${application_id}`, {
        headers: { Authorization: `Basic ${this.getToken()}` },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          if (data) {
            this.setState({
              preEduFinancial: data,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
      .catch((error) => {
        this.setState({
          isLoaded: true,
        });
      });
  };
  /**
   * Same as above without side-effects
   */
  getPreEduFinancial = () => {
    axios
      .get(
        `${getSLIM4APIUrl()}user/financialHelpHistory/${
          this.state.personalDetails[0].application_id
        }`,
        {
          headers: { Authorization: `Basic ${this.getToken()}` },
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (data) {
          this.setState({
            preEduFinancial: data,
          });
        } else {
          this.setState({
            preEduFinancial: [],
          });
        }
      });
  };
  /** Map eduData from array to the form used in the frontend */
  mapEduData = (threeYearEdu) => {
    const eduDetails = {};
    for (const data of threeYearEdu) {
      if (data && data.edu_type === 1) {
        this.getCityList(data["country"], "ptay_10th_country_list");
        eduDetails["se_school_name"] = data["institute_name"];
        eduDetails["se_instruction_medium"] = data["instruction_medium"];
        eduDetails["ptay_10th_country"] = data["country"];
        eduDetails["ptay_10th_city"] = data["city"];
        eduDetails["se_board"] = data["board"];
        eduDetails["ptay_10th_passing_year"] = data["passing_year"];
        eduDetails["ptay_10th_percentage"] = data["percentage"];
        eduDetails["ptay_10th_preferred_subject"] = data["preferred_subject"];
        eduDetails["ptay_10th_percentage_preferred_subject"] =
          data["preferred_subject_percentage"];
      } else if (data && data.edu_type === 2) {
        this.getCityList(data["country"], "ptay_12th_country_list");
        eduDetails["hse_school_name"] = data["institute_name"];
        eduDetails["hse_instruction_medium"] = data["instruction_medium"];
        eduDetails["ptay_12th_country"] = data["country"];
        eduDetails["ptay_12th_city"] = data["city"];
        eduDetails["hse_board"] = data["board"];
        eduDetails["ptay_12th_passing_year"] = data["passing_year"];
        eduDetails["ptay_12th_percentage"] = data["percentage"];
        eduDetails["ptay_12th_preferred_subject"] = data["preferred_subject"];
        eduDetails["ptay_12th_percentage_preferred_subject"] =
          data["preferred_subject_percentage"];
      } else if (data && data.edu_type === 3) {
        this.getCityList(data["country"], "ptay_grd_country_list");
        eduDetails["grd_school_name"] = data["institute_name"];
        eduDetails["grd_course_name"] = data["course_name"];
        eduDetails["ptay_grd_passing_year"] = data["passing_year"];
        eduDetails["ptay_grd_percentage"] = data["percentage"];
        eduDetails["ptay_grd_preferred_subject"] = data["preferred_subject"];
        eduDetails["ptay_grd_percentage_preferred_subject"] =
          data["preferred_subject_percentage"];
        eduDetails["ptay_grd_country"] = data["country"];
        eduDetails["ptay_grd_city"] = data["city"];
      }
    }
    return [eduDetails];
  };
  getAcademicDetails = (trackId, role, application_id) => {
    this.addNewArazLoader();
    var url = getSLIM4APIUrl();
    axios
      .get(url + `user/academicDetails/${application_id}`, {
        headers: { Authorization: `Basic ${this.getToken()}` },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          if (data && Object.keys(data).length > 0) {
            let LastThreeYearsArr = [];
            LastThreeYearsArr.push(data.GraduationDetails);
            LastThreeYearsArr.push(data.HigherSecondaryDetails);
            LastThreeYearsArr.push(data.SecondaryDetails);
            this.setState({
              eduDetails: LastThreeYearsArr.length
                ? this.mapEduData(LastThreeYearsArr)
                : this.state.acaEduDetails,
              // lastThreeYearEdu: data.LastThreeYears.length
              //   ? data.LastThreeYears
              //   : this.state.lastThreeYearEdu,
              // entranceExam: data.EntranceExams.length
              //   ? data.EntranceExams
              //   : this.state.entranceExam,
              // achivementsSchoolCollege: data.acheivements.length
              //   ? data.acheivements
              //   : this.state.achivementsSchoolCollege,
            });
            this.getCountryList(application_id);
            if (
              data &&
              data.acaEduDetails &&
              data.acaEduDetails[0].ptay_grd_country
            ) {
              this.getCityList(data.acaEduDetails[0].ptay_grd_country);
            }
          }
          this.setState({
            isLoadedAC: false,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
      .finally(() => this.removeNewArazLoader());
  };
  getCountryList = () => {
    var url = getSLIM4APIUrl();
    if (this.state.countries.length > 0) return;
    this.addNewArazLoader();
    axios
      .get(url + `user/countries`, {
        headers: { Authorization: `Basic ${this.getToken()}` },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          this.setState({
            countries: data.length ? data : this.state.countries,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
      .finally(() => this.removeNewArazLoader());
  };

  getCountryList2 = () => {
    return new Promise((resolved, reject) => {
      var url = getSLIM4APIUrl();
      // if (this.state.countries.length > 0) return;
      this.addNewArazLoader();
      axios
        .get(url + `user/countries`, {
          headers: { Authorization: `Basic ${this.getToken()}` },
        })
        .then((response) => response.data)
        .then(
          (data) => {
            if (data) {
              resolved(data);
            } else {
              reject(data);
            }
          },
          (error) => {
            reject(error);
          }
        )
        .finally(() => this.removeNewArazLoader());
    });
  };

  getCityList = (iso_2, name) => {
    if (!iso_2) return;
    let finaleName = name;
    if (!name) finaleName = "cities";
    this.addNewArazLoader();
    var url = this.state.apiUrl;
    axios
      .get(url + "user/cities/" + iso_2)
      .then((response) => response.data)
      .then(
        (data) => {
          if (finaleName !== "cities") {
            this.setState({
              [finaleName]: data.length ? data : this.state.cities,
            });
          } else {
            this.setState({
              cities: data.length ? data : this.state.cities,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
      .finally(() => this.removeNewArazLoader());
  };
  toMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };
  getMonthFromString = (mon) => {
    return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
  };
  getCourseFeesDetails = (trackId, role, application_id) => {
    // var url = this.state.apiUrl;
    // axios.get(url+'course_fees/'+trackId+'/'+getUser().its_id+'/'+role)
    this.addNewArazLoader();
    var url = getSLIM4APIUrl();
    axios
      .get(url + `user/courseDetailsAndFeesStructure/${application_id}`, {
        headers: { Authorization: `Basic ${this.getToken()}` },
      })
      .then(
        ({ data }) => {
          const dat = data[0] || {};

          this.getCountryList2(application_id).then((countriesdata) => {
            let cotry = countriesdata.find((item1) => {
              return item1.name == dat["institute_country"];
            });
            const courseDetails = [
              {
                cd_course_name: dat["course_name"] || "",
                cd_career_interest: dat["career_interest"] || "",
                cd_course_duration: dat["course_duration"] || "",
                cf_total_personal_funds: dat["total_personal_funds"] || "",
                fs_college_name: dat["institute_name"] || "",
                fs_institute_country: (cotry && cotry.iso2) || "",
                fs_institute_city: dat["institute_city"] || "",
                fs_course_start_month: `${dat["course_start_year"]}-${
                  this.getMonthFromString(dat["course_start_month"]) < 10
                    ? "0" + this.getMonthFromString(dat["course_start_month"])
                    : this.getMonthFromString(dat["course_start_month"])
                }`,
                fs_course_start_year: dat["course_start_year"] || "",
                fs_accommodation: dat["accommodation"] || "",
              },
            ];
            const feesStructure = [
              {
                fs_1st_y_tution_fees: dat["tution_fees_year_1"] || "",
                fs_2st_y_tution_fees: dat["tution_fees_year_2"] || "",
                fs_3rd_y_tution_fees: dat["tution_fees_year_3"] || "",
                fs_4th_y_tution_fees: dat["tution_fees_year_4"] || "",
                fs_5th_y_tution_fees: dat["tution_fees_year_5"] || "",
                index: dat["id"] || Math.floor(Math.random() * 10),
                fs_hostel_fees: dat["hostel_fees"] || "0",
                fs_b_s_expenses_fees: dat["books_expenses"] || "",
                fs_travel_expenses_fees: dat["travel_expenses"] || "",
                fs_other_expenses_fees: dat["other_expenses"] || "",
                fs_edu_total_cost: dat["education_total_cost"] || "",
              },
            ];
            this.setState({
              feesStructure: feesStructure,
              courseDetails: courseDetails,
              fs_edu_total_cost: dat["education_total_cost"],
            });

            this.getCityList(
              courseDetails.length ? courseDetails[0].fs_institute_country : 0
            );
            this.setState({
              isLoadedFS: false,
            });
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      )
      .finally(() => this.removeNewArazLoader());
  };
  getTrackIdFromAppId = (appId) => {
    var url = this.state.apiUrl;
    axios
      .get(url + "get/track_id_from_app_id/" + appId)
      .then((response) => response.data)
      .then(
        (data) => {
          if (data.response) {
            this.setState({
              track_id: data.data,
            });
          }
          this.setState({
            isLoadedFS: false,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };
  addNewArazLoader = () =>
    this.setState((prev) => ({
      ...prev,
      newArazLoader: prev.newArazLoader + 1,
    }));
  removeNewArazLoader = () =>
    this.setState((prev) => ({
      ...prev,
      newArazLoader: prev.newArazLoader - 1,
    }));
  sendFaPersonal = async (callback) => {
    this.addNewArazLoader();
    try {
      let marhala = localStorage.getItem("marhala");
      const data = new FormData(); // Currently empty;
      data.append(
        "application_id",
        localStorage.getItem("araz.application_id")
      );
      data.append("hof_its", this.state.personalDetails[0].hof_its);
      data.append("email", this.state.personalDetails[0].email);
      data.append("contact_no", this.state.personalDetails[0].contact_no);
      data.append("address", this.state.personalDetails[0].address);
      data.append(
        "cheque_full_name",
        this.state.personalDetails[0].cheque_full_name
      );

      let path = getSLIM4APIUrl() + "user/personalDetails";
      const personDetailsReq = fetch(path, {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      });
      let familyMembers = [];
      for (const fm of this.state.familyMember) {
        let currentFm = {};
        for (const item of Object.entries(fm)) {
          if (item[0].indexOf("_error") === -1) currentFm[item[0]] = item[1];
        }
        familyMembers.push(currentFm);
      }
      const familyMembersReq = new Promise((resolved, reject) => {
        axios
          .post(`${getSLIM4APIUrl()}user/familyMembers`, familyMembers, {
            headers: {
              Authorization: `Basic ${this.getToken()}`,
            },
          })
          .then((data) => {
            resolved(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
      await Promise.all([personDetailsReq, familyMembersReq]).then(() => {
        toast.success("Personal information updated successfully");
        callback instanceof Function && callback(null);
        this.setStep(2);
      });
    } catch (err) {
      console.log(err);
      toast.error("Some Error occured");
    }
    this.removeNewArazLoader();
    // .then((response) => response.json())
    // .then((result) => {
    // 	if (result.success === false) {
    // 		alert('Sorry! Something happen wrong. Please try again later.');
    // 	}
    // })
  };
  sendFaAcademic = async () => {
    this.addNewArazLoader();
    try {
      let data = {}; // Currently empty;
      for (const item of Object.entries(this.state.eduDetails[0])) {
        const [k, val] = item;
        if (k.indexOf("_error") === -1) data[[k]] = val;
      }

      var path =
        getSLIM4APIUrl() +
        "user/academicDetails/" +
        this.state.personalDetails[0].application_id;
      // const res = await
      axios
        .post(path, data, {
          headers: {
            Authorization: `Basic ${this.getToken()}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.setState({ isLoadedFS: false });
          this.setStep(3);
          toast.success("Academic Details updated successfully");
        })
        .catch(() => {
          toast.warn(
            "Something went wrong updating the academic details, try again."
          );
        });
    } catch (err) {
      toast.warn(
        "Something went wrong updating the academic details, try again."
      );
    }
    this.removeNewArazLoader();
  };
  sendFaCover = async () => {
    this.addNewArazLoader();
    try {
      const application_id = this.state.personalDetails[0].application_id;
      let data = {};
      /** mapping between API's accepted field and field used in react */
      const mapping = {
        fs_1st_y_tution_fees: "tution_fees_year_1",
        fs_2st_y_tution_fees: "tution_fees_year_2",
        fs_3rd_y_tution_fees: "tution_fees_year_3",
        fs_4th_y_tution_fees: "tution_fees_year_4",
        fs_5th_y_tution_fees: "tution_fees_year_5",
        fs_college_name: "institute_name",
        fs_institute_country: "institute_country",
        fs_institute_city: "institute_city",
        fs_course_start_month: "course_start_month",
        fs_course_start_year: "course_start_year",
        fs_accommodation: "accommodation",
        fs_hostel_fees: "hostel_fees",
        fs_b_s_expenses_fees: "books_expenses",
        fs_travel_expenses_fees: "travel_expenses",
        fs_other_expenses_fees: "other_expenses",
        fs_edu_total_cost: "education_total_cost",
      };
      let courDetails = this.state.courseDetails[0];
      courDetails.cd_course_name = this.state.courseDetails[0].cd_course_name
        .length
        ? this.state.courseDetails[0].cd_course_name
        : this.state.arazPreviewInfo[0] &&
          this.state.arazPreviewInfo[0].course_name;
      courDetails.institute_name = this.state.courseDetails[0].fs_college_name;
      courDetails.institute_country =
        this.state.courseDetails[0].fs_institute_country;
      courDetails.institute_city =
        this.state.courseDetails[0].fs_institute_city;
      courDetails.course_start_month =
        this.state.courseDetails[0].fs_course_start_month;
      courDetails.course_start_year =
        this.state.courseDetails[0].fs_course_start_month;
      courDetails.accommodation = this.state.courseDetails[0].fs_accommodation;
      for (const item of Object.entries({
        ...courDetails,
        ...this.state.feesStructure[0],
      })) {
        const key = mapping[item[0]] || item[0];
        if (item[0].indexOf("_error") === -1 && key == "institute_country") {
          let countryObj = this.state.countries.find(
            (data1) => data1.iso2 == item[1]
          );
          data[key] = countryObj && countryObj.name;
        } else {
          if (item[0].indexOf("_error") === -1) data[key] = item[1];
        }
      }
      const res = await fetch(
        `${getSLIM4APIUrl()}user/courseDetailsAndFeesStructure/${application_id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${this.getToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (res.ok) {
        // this.setState({ isLoadedFS: false });
        this.setStep(4);
        toast.success("Fees Details updated successfully");
      } else {
        toast.warn(
          "Something went wrong updating the fees details, try again."
        );
      }
    } catch (err) {
      toast.warn("Something went wrong updating the fees details, try again.");
      console.error({ function: "sendFaCover", error: err });
    }
    this.removeNewArazLoader();
  };
  getArazInfoForPreview = (trackId) => {
    return;
    var url = this.state.arazApiUrl;
    axios
      .get(url + "get/araz_preview_info/" + trackId + "/" + getUser().its_id)
      .then((response) => response.data)
      .then(
        (data) => {
          if (data.response) {
            this.setState({
              arazPreviewInfo: data.arazPreviewInfo,
              previewArazUserInfo: data.userInfo,
              user_tanzeem_id: data.userInfo.tanzeem_id,
            });
            localStorage.setItem("user_tanzeem_id", data.userInfo.tanzeem_id);
          }
          this.setState({
            isLoadedAP: false,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  submitFinalAraz = async () => {
    this.addNewArazLoader();
    try {
      const application_id = this.state.personalDetails[0].application_id;
      const res = await fetch(
        `${getSLIM4APIUrl()}user/submitApplication/${application_id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${this.getToken()}`,
          },
        }
      );
      if (res.ok) {
        toast.success("Successfully submitted New Araz");
        this.props.history.push("/applicant/dashboard");
        window.location.reload();
      } else {
        throw "Error";
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong submitting the application.");
    }
    this.removeNewArazLoader();
  };

  approveFaPersonal = (appId) => {
    var inputElems = document.getElementsByTagName("input"),
      totalFields = 0,
      totalApproved = 0;
    for (var i = 0; i < inputElems.length; i++) {
      if (inputElems[i].type === "checkbox") {
        totalFields++;
      }
      if (inputElems[i].type === "checkbox" && inputElems[i].checked === true) {
        totalApproved++;
      }
    }
    let marhala = localStorage.getItem("marhala");
    const data = new FormData(); // Currently empty;
    data.append("fa-personal", JSON.stringify(this.state.personalDetails));
    data.append(
      "fa-pre-education-finance-help",
      JSON.stringify(this.state.preEduFinancial)
    );
    data.append("fa-family-members", JSON.stringify(this.state.familyMember));
    data.append("totalFields", totalFields);
    data.append("totalApproved", totalApproved);
    let path =
      getSLIM4APIUrl() +
      "approve/fa_personal_info/" +
      getUser().its_id +
      "/" +
      appId +
      "/" +
      marhala;
    fetch(path, {
      method: "post",
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === false) {
          alert("Sorry! Something happen wrong. Please try again later.");
        }
      });
  };
  approveFaAcademic = (appId) => {
    const data = new FormData(); // Currently empty;
    data.append("eduDetails", JSON.stringify(this.state.eduDetails));
    data.append("entranceExam", JSON.stringify(this.state.entranceExam));
    data.append(
      "achivementsSchoolCollege",
      JSON.stringify(this.state.achivementsSchoolCollege)
    );

    var inputElems = document.getElementsByTagName("input"),
      totalFields = 0,
      totalApproved = 0;
    for (var i = 0; i < inputElems.length; i++) {
      if (inputElems[i].type === "checkbox") {
        totalFields++;
      }
      if (inputElems[i].type === "checkbox" && inputElems[i].checked === true) {
        totalApproved++;
      }
    }
    data.append("totalFields", totalFields);
    data.append("totalApproved", totalApproved);
    var path =
      getSLIM4APIUrl() +
      "approve/fa_academic_info/" +
      getUser().its_id +
      "/" +
      appId;
    fetch(path, {
      method: "post",
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        this.getArazInfoForPreview();
        if (result.success === false) {
          alert("Sorry! Something happen wrong. Please try again later.");
        }
      });
  };

  approveFaFees = (appId) => {
    const data = new FormData(); // Currently empty;
    data.append("courseDetails", JSON.stringify(this.state.courseDetails));
    data.append("feesStructure", JSON.stringify(this.state.feesStructure));

    var inputElems = document.getElementsByTagName("input"),
      totalFields = 0,
      totalApproved = 0;
    for (var i = 0; i < inputElems.length; i++) {
      if (inputElems[i].type === "checkbox") {
        totalFields++;
      }
      if (inputElems[i].type === "checkbox" && inputElems[i].checked === true) {
        totalApproved++;
      }
    }
    data.append("totalFields", totalFields);
    data.append("totalApproved", totalApproved);
    var path =
      getSLIM4APIUrl() +
      "approve/fa_course_fees_info/" +
      getUser().its_id +
      "/" +
      appId;
    fetch(path, {
      method: "post",
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === false) {
          alert("Sorry! Something happen wrong. Please try again later.");
        } else {
        }
      });
  };
  submitFinalApproval = (appId) => {
    const data = new FormData(); // Currently empty;
    data.append("docsApprove", JSON.stringify(this.state.docsApprove));
    data.append("countFiles", this.state.countFiles);

    var inputElems = document.getElementsByTagName("input"),
      totalFields = 0,
      totalApproved = 0;
    for (var i = 0; i < inputElems.length; i++) {
      if (inputElems[i].type === "checkbox") {
        totalFields++;
      }
      if (inputElems[i].type === "checkbox" && inputElems[i].checked === true) {
        totalApproved++;
      }
    }
    data.append("totalFields", JSON.stringify(totalFields));
    data.append("totalApproved", JSON.stringify(totalApproved));
    var path =
      getSLIM4APIUrl() + "approve/fa_submit/" + getUser().its_id + "/" + appId;
    fetch(path, {
      method: "post",
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        this.props.history.push("#/co/dashboard");
        window.location.reload();

        this.setState({
          redirectToDashboard: true,
        });
      });
  };
  getTanzeemWiseAraz = (tanzeem_id) => {
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}aamilSaheb/pendingApplicationsList`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          if (data) {
            let newData = data?.map((d) => ({
              ...d,
              total_days: daysBetween(new Date(), d.applied_date),
            }));
            this.setState({
              tanzeemNewArazList: newData,
            });
          } else {
            this.setState({
              tanzeemNewArazList: [],
            });
          }
          this.setState({
            isLoadedTNA: false,
          });
        },
        (error) => {
          this.setState({
            isLoadedTNA: true,
            error,
          });
        }
      );
  };

  refreshArazList = () => this.getArazListAsRoleWise(0, this.state.arazListTab);

  getArazListAsRoleWise = (role, status, codition = false) => {
    this.setState({
      isLoadedALR: true,
      arazListAsRoleWise: [],
      arazListTab: status,
    });
    this.getCounsellorList();
    this.getTrusteeList();
    this.getETrusteeList();
    const transformData = (data) =>
      data.map((d) => ({
        ...d,
        total_days: daysBetween(new Date(), d.applied_date),
      }));
    const statusToUrl = {
      1: "getOnlineApplicationsList",
      2: "getDocumentsPendingList",
      3: "getInterviewList",
      4: "getPresanctionList",
      5: "getCallForPledgeList",
      6: "getSanctioningList",
      7: "getAccountsList",
    };
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}coord/${statusToUrl[Number(status)]}`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          if (data.length) {
            this.setState({
              arazListAsRoleWise: transformData(data),
            });
          }
          this.setState({
            isLoadedALR: false,
          });
        },
        (error) => {
          this.setState({
            isLoadedALR: true,
            error,
          });
        }
      );
  };
  getApplicationInfo = (app_id) => {
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}trustee/applicationDetails/${app_id}`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then(
        (data) => {
          if (data?.status == 200) {
            this.setState({
              applicationInfo: data?.data,
            });
          }
          this.setState({
            isLoadedTNA: false,
          });
        },
        (error) => {
          this.setState({
            isLoadedTNA: true,
            error,
          });
        }
      );
  };

  getExecTrusteeApplicationInfo = (app_id) => {
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}exec-trustee/applicationDetails/${app_id}`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then(
        (data) => {
          if (data.status == 200) {
            this.setState({
              execTrusteeApplicationInfo: data.data,
            });
          }
        },
        (error) => {
          this.setState({
            execTrusteeApplicationInfo: [],
          });
        }
      );
  };

  getTanzeemWiseArazHistory = (tanzeem_id) => {
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}aamilSaheb/history`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          if (data) {
            this.setState({
              tanzeemNewArazHistoryList: data,
            });
          } else {
            this.setState({
              tanzeemNewArazHistoryList: [],
            });
          }
          this.setState({
            isLoadedTNAH: false,
          });
        },
        (error) => {
          this.setState({
            isLoadedTNA: true,
            error,
          });
        }
      );
  };
  getRequiredFiles = (app_id) => {
    var url = this.state.apiUrl;
    fetch(url + "get/required_files/" + app_id, {
      method: "get",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.response === false) {
        } else {
          if (result.response) {
            this.setState({
              requiredFiles: result.data,
              countFiles: result.countFiles,
              docsApprove: result.data,
            });
          }
        }
        this.setState({
          isLoadedDocs: false,
        });
      });
  };
  getAppChatHistory = (app_id) => {
    var url = getSLIM4APIUrl();
    axios
      .get(url + "coord/notes/" + app_id, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        if (result && result.length > 0) {
          this.setState({
            chatHistory: result.length ? result : this.state.chatHistory,
          });
        }
        this.setState({
          isLoadedAppChat: false,
        });
      });
  };

  getAppStatusHistory = (app_id) => {
    var url = this.state.apiUrl;
    this.setState({
      isLoadedAppStatus: false,
    });
    // fetch(url+'get/app-status-history/'+app_id+'/'+getUser().its_id, {
    // 	method: 'get'
    // })
    fetch(getSLIM4APIUrl() + "user/latestApplicationStatusHistory", {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          appStatusHistory: result,
        });

        this.setState({
          isLoadedAppStatus: true,
        });
      });
  };
  getAppList = () => {
    var url = this.state.apiUrl;
    fetch(getSLIM4APIUrl() + "user/allApplications", {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          appList: result || [],
        });

        this.setState({
          isLoadedAppList: false,
        });
      });
  };
  getCordinatorList = () => {
    let endpoint = "coord/coordinatorList";
    fetch(`${getSLIM4APIUrl()}${endpoint}`, {
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          coList: data?.map((item) => {
            return { label: item.name, value: item.its_id };
          }),
        });
      })
      .catch((err) => console.log("err", err));
  };
  getAppStatusList = () => {
    // var url = this.state.apiUrl;
    const transformData = (data) =>
      Object.entries(data).map((o, idx) => ({
        id: idx + 1,
        is_active: "1",
        status: `${o[0]} (${o[1]})`,
        name: o[0],
      }));
    fetch(`${getSLIM4APIUrl()}coord/applicationCounts`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
        } else {
          if (result) {
            this.getArazListAsRoleWise(
              0,
              Number(localStorage.getItem("active-co-cardtab")) || 1
            ); // used for get app list as per the status wise
            this.setState({
              appStatusList: transformData(result),
            });
          }
        }
        this.setState({
          isLoadedASL: false,
        });
      });
  };
  getCounsellorList = () => {
    if (
      Array.isArray(this.state.counsellorList) &&
      this.state.counsellorList.length > 0
    )
      return;
    var url = this.state.arazApiUrl;
    fetch(`${getSLIM4APIUrl()}coord/counsellorList`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
        } else {
          if (Array.isArray(result)) {
            this.getCordinatorList();
            this.setState({
              counsellorList: result,
            });
          }
        }
        this.setState({
          isLoadedCounList: false,
        });
      });
  };
  getTrusteeList = () => {
    // old url
    // https://reacttest.talabulilm.com/react-api/api/get/trustee-list
    // var url = this.state.apiUrl;
    if (
      Array.isArray(this.state.trusteeList) &&
      this.state.trusteeList.length > 0
    )
      return;
    fetch(`${getSLIM4APIUrl()}coord/trusteeList`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response?.json())
      .then((result) => {
        if (result === false) {
        } else {
          if (Array.isArray(result)) {
            this.setState({
              trusteeList: result,
            });
          }
        }
        this.setState({
          isLoadedTrusteeList: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadedTrusteeList: false,
        });
      });
  };
  getETrusteeList = () => {
    if (
      Array.isArray(this.state.eTrusteeList) &&
      this.state.eTrusteeList.length > 0
    )
      return;
    // var url = this.state.apiUrl;
    fetch(`${getSLIM4APIUrl()}coord/executiveTrusteeList`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
        } else {
          if (Array.isArray(result)) {
            this.setState({
              eTrusteeList: result,
            });
          }
        }
        this.setState({
          isLoadedETrusteeList: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadedETrusteeList: false,
        });
      });
  };

  getAdminStatusList = (activeId) => {
    if (Array.isArray(this.state.adminList) && this.state.adminList.length > 0)
      return;
    var url = this.state.arazApiUrl;
    const transformData = (data) =>
      Object.entries(data).map((o, idx) => ({
        id: idx + 1,
        is_active: "1",
        status: `${o[0]} (${o[1]})`,
      }));

    fetch(`${getSLIM4APIUrl()}admin/applicationCounts`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          this.getCordinatorList();
          this.getCounsellorList();

          // this.getAdminListAsRoleWise(activeId || 1);
          this.setState({
            adminStatusList: result,
          });
        }
        this.setState({ isLoadedCounList: false });
      })
      .catch(() => {
        this.setState({ isLoadedCounList: false });
      });
  };

  getAdminListAsRoleWise = (tagIndex) => {
    const statusToUrl = {
      1: "getNewApplicationsList",
      2: "getAtCOordinatorPendingList",
      3: "getAtCounsellorPendingList",
      4: "getAtAamilSahebPendingList",
      5: "getAtTrusteePendingList",
      6: "getAtExecTrusteePendingList",
      7: "getAtAccountsPendingList",
      8: "getCompletedAppList",
      9: "getDocumentsPendingList",
    };

    this.setState({
      isLoadedAdminLoading: true,
    });

    axios
      .get(`${getSLIM4APIUrl()}admin/${statusToUrl[Number(tagIndex)]}`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((result) => {
        if (result && result.status == 200) {
          this.setState({
            adminList: result.data,
          });
        } else {
          this.setState({
            adminList: [],
          });
        }
        this.setState({
          isLoadedAdminLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadedAdminLoading: false,
          adminList: [],
        });
      });
  };
  verifyDetailByCoOrdinators = ({ data, head, app_id }) => {
    let headFinal = "";
    let idFinal = data.id;
    let comonHead = "verify" + head.replaceAll(" ", "");

    if (head === "Personal Details") {
      headFinal = "verifyPersonalDetails";
      idFinal = app_id;
    } else if (head === "Family Members") {
      headFinal = "veriFamilyMember";
    } else if (head === "Previous Financial Help recieved") {
      headFinal = "verifyPrevFinancialHelp";
    } else if (head == "Secondary Education Details") {
      headFinal = "verifySecondaryEducation";
      idFinal = app_id;
    } else if (head === "Higher Education Details") {
      headFinal = "verifyHigherEducation";
      idFinal = app_id;
    } else if (head === "Graduation Details") {
      headFinal = "verifyGraduateEducation";
      idFinal = app_id;
    } else if (head === "Entrance Exam Details") {
      headFinal = "verifyEntranceExam";
    } else if (head === "Achivements Details") {
      headFinal = "verifyAchivement";
    } else if (head === "Fees Structure") {
      headFinal = "verifyFeesStructure";
      idFinal = app_id;
    } else if (head === "Course Details") {
      headFinal = "verifyCourse";
      idFinal = app_id;
    } else {
      headFinal = comonHead;
      idFinal = data.its_id;
    }
    axios
      .patch(`${getSLIM4APIUrl()}coord/${headFinal}/${idFinal}`, data, {
        headers: {
          Authorization: `Basic ${localStorage.getItem("fa-token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result) {
          toast.success("Verify update.");
          // this.getQuestionsList();
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
      })
      .finally(() => this.setState({ loading: false }));
  };
  addAppChatHistory = (appId, its_id, message) => {
    this.setState({
      isLoadedAppChat: true,
    });
    // const data = new FormData(); // Currently empty;
    // data.append("message", message);
    // data.append("its_id", its_id);

    let newFinalObject = {};
    newFinalObject.its_id = its_id;
    newFinalObject.note = message;

    var path = getSLIM4APIUrl() + "coord/notes/" + appId;
    fetch(path, {
      method: "post",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newFinalObject),
    })
      .then((response) => response.json())
      .then((result) => {
        this.getAppChatHistory(appId);
      })
      .catch((error) => {
        this.setState({
          isLoadedAppChat: false,
        });
      });
  };
  appliedAmount = (total_cost, total_personal_funds) => {
    return Math.abs(Number(total_cost) - Number(total_personal_funds));
  };
  assignApp = (appId, role, assign_by, assign_to) => {
    this.setState({ cooDashBoardLoading: true });
    const path = `${getSLIM4APIUrl()}coord/assignMe`;
    const data = new FormData();
    data.append("applicationID", appId);
    fetch(path, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.getAppStatusList();
        this.getArazListAsRoleWise(
          0,
          Number(localStorage.getItem("active-co-cardtab"))
        );
        toast.success("Successfully assigned the application");
      })
      .finally(() => this.setState({ cooDashBoardLoading: false }));
  };
  deleteApp = (appId) => {
    this.setState({ cooDashBoardLoading: true });
    const path = `${getSLIM4APIUrl()}coord/application/${appId}`;
    fetch(path, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.getAppStatusList();
        this.getArazListAsRoleWise(
          0,
          Number(localStorage.getItem("active-co-cardtab"))
        );
        toast.success("Successfully deleted the application");
      })
      .finally(() => this.setState({ cooDashBoardLoading: false }));
  };

  // set the token
  setToken = () => {
    const user_ite = this.getCookie("user_its");
    const baseUrl = process.env.REACT_APP_CONTEXT_URL;
    const apiUrl = process.env.REACT_APP_CONTEXT_API_URL;
    if (user_ite === null) {
      // this URL is for bchet.talabulilm
      // window.location.replace(
      //   "https://www.its52.com/Login.aspx?OneLogin=MHB&tlbre=YmNoZXQudGFsYWJ1bGlsbS5jb20="
      // );
      // this URL is for testbchet.talabulilm
      window.location.replace(`${baseUrl}${apiUrl}`);
      return;
    }
    console.log(apiUrl, "contextAPiUrl");
    console.log(baseUrl, "contextBaseUrl");
    // if role is not set, then run API
    // if cookie user_its and localstorage"user"->its_id do not match
    // then call

    var username = user_ite;
    var password = this.getCookie("ver");
    // this.getCookie("ver") != null
    //   ?
    //   : "c665872b7193541130e89d2ecbc8dc33";

    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );

    if (
      this.getCookie("role") === null ||
      this.getCookie("tanzeem_id") === null ||
      user_ite !== JSON.parse(localStorage.getItem("user"))?.its_id
    ) {
      const headers = { "Content-Type": "application/json" };
      if (token) {
        headers["Authorization"] = `Basic ${token}`;
      }
      fetch(`${getSLIM4APIUrl()}`, {
        headers: headers,
      })
        .then((res) => res.json())
        .then((json) => {
          document.cookie = `user_its=${json.its_id}`;
          document.cookie = `ver=${password}`;
          document.cookie = `role=${json.role}`;
          document.cookie = `tanzeem_id=${json.tanzeem_id}`;

          const user = {
            its_id: json.its_id,
            role: json.role,
            tanzeem_id: json.tanzeem_id,
          };
          this.setState({
            role: String(json.role),
            redirectToReferrer: true,
          });
          localStorage.setItem("user", JSON.stringify(user));
        });
    }

    localStorage.setItem("fa-token", token);
    return token;
  };
  getToken = () => {
    return (
      localStorage.getItem("fa-token") ||
      this.setState({ redirectForNotAuthorized: false })
    );
  };

  // getTrusteeApprovalRoleWise = (role, status, codition = false) => {
  //   const transformData = (data) =>
  //     data.map((d) => ({
  //       ...d,
  //       total_days: daysBetween(new Date(), d.applied_date),
  //     }));
  //   const statusToUrl = {
  //     1: "getApprovalList",
  //     2: "getSecondApprovalList",
  //   };
  //   var url = getSLIM4APIUrl();
  //   axios
  //     .get(`${url}trustee/${statusToUrl[Number(status)]}`, {
  //       headers: {
  //         Authorization: `Basic ${this.getToken()}`,
  //       },
  //     })
  //     .then((response) => response.data)
  //     .then(
  //       (data) => {
  //         if (data.length) {
  //           if (Number(status) == 1) {
  //             this.setState({
  //               trusteeApprovalApprovalList: transformData(data),
  //             });
  //           } else {
  //             this.setState({
  //               trusteeApprovalSecondApprovalList: transformData(data),
  //             });
  //           }
  //           // this.setState({
  //           //   trusteeListAsRoleWise: transformData(data),
  //           // });
  //         } else {
  //           if (!codition) {
  //             this.setState({
  //               trusteeApprovalApprovalList: [],
  //               trusteeApprovalSecondApprovalList: [],
  //             });
  //           }
  //         }
  //         this.setState({
  //           isTrusteeListAsRoleWiseLoading: false,
  //         });
  //       },
  //       (error) => {
  //         this.setState({
  //           trusteeListAsRoleWise: [],
  //         });
  //         this.setState({
  //           isTrusteeListAsRoleWiseLoading: true,
  //           error,
  //         });
  //       }
  //     );
  // };

  getCounselorApprovalRoleWise = (role, status, codition = false) => {
    const transformData = (data) =>
      data.map((d) => ({
        ...d,
        total_days: daysBetween(new Date(), d.assigned_ts),
      }));
    const statusToUrl = {
      1: "getPendingApplications",
      2: "getSecondApprovalList",
    };
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}Counselor/${statusToUrl[Number(status)]}`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          if (data.length) {
            this.setState({
              counselorListAsRoleWise: transformData(data),
            });
          } else {
            this.setState({
              counselorListAsRoleWise: [],
            });
          }
          this.setState({
            isCounselorListAsRoleWiseLoading: false,
          });
        },
        (error) => {
          this.setState({
            isCounselorListAsRoleWiseLoading: true,
            error,
          });
        }
      );
  };

  getCounsellorApplicationInfo = (app_id) => {
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}Counselor/applicationDetails/${app_id}`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then(
        (data) => {
          if (data.status == 200) {
            this.setState({
              counsellorApplicationInfo: data.data,
            });
          }
          this.setState({
            isLoadedTNA: false,
          });
        },
        (error) => {
          this.setState({
            isLoadedTNA: true,
            error,
          });
        }
      );
  };

  // getTrusteeApplicationCountsList = () => {
  //   const transformData = (data) =>
  //     Object.entries(data).map((o, idx) => ({
  //       id: idx + 1,
  //       is_active: "1",
  //       status: `${o[0]} (${o[1]})`,
  //     }));
  //   fetch(`${getSLIM4APIUrl()}trustee/getApplicationCounts  `, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Basic ${this.getToken()}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result === false) {
  //       } else {
  //         if (result) {
  //           let activeTab = localStorage.getItem("active-trustee-cardtab");
  //           this.getTrusteeApprovalRoleWise(0, Number(activeTab) || 1);
  //           this.setState({
  //             trusteeApplicationCountsList: transformData(result),
  //           });
  //         }
  //       }
  //       this.setState({
  //         isTrusteeApplicationCountsLoading: false,
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         trusteeApplicationCountsList: [],
  //       });
  //     });
  // };

  getCounselorApplicationCountsList = () => {
    const transformData = (data) =>
      Object.entries(data).map((o, idx) => ({
        id: idx + 1,
        is_active: "1",
        status: `${o[0]} (${o[1]})`,
      }));
    fetch(`${getSLIM4APIUrl()}Counselor/getApplicationCounts`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
        } else {
          if (result) {
            this.getCounselorApprovalRoleWise(0, 1);
            this.setState({
              counselorApplicationCountsList: transformData(result),
            });
          }
        }
        this.setState({
          isCounselorApprovalCountsLoading: false,
        });
      });
  };

  getCounselorApprovalList = () => {
    const transformData = (data) =>
      Object.entries(data).map((o, idx) => ({
        id: idx + 1,
        is_active: "1",
        status: `${o[0]} (${o[1]})`,
      }));
    fetch(`${getSLIM4APIUrl()}Counselor/getApprovalList  `, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
        } else {
          if (result) {
            // this.getCounselorApprovalRoleWise(0, 1);
            this.setState({
              counselorApprovalList: transformData(result),
            });
          }
        }
        this.setState({
          isCounselorApprovalCountsLoading: false,
        });
      });
  };

  getExecTrusteeApprovalRoleWise = (role, status, codition = false) => {
    const transformData = (data) =>
      data.map((d) => ({
        ...d,
        total_days: daysBetween(new Date(), d.applied_date),
      }));
    const statusToUrl = {
      1: "getApprovalList",
      2: "getSecondApprovalList",
    };
    var url = getSLIM4APIUrl();
    axios
      .get(`${url}exec-trustee/${statusToUrl[Number(status)]}`, {
        headers: {
          Authorization: `Basic ${this.getToken()}`,
        },
      })
      .then((response) => response.data)
      .then(
        (data) => {
          if (data.length) {
            this.setState({
              execTrusteeApprovalRoleWiseList: transformData(data),
            });
          }
          this.setState({
            isExecTrusteeApprovalRoleWiseLoading: false,
          });
        },
        (error) => {
          this.setState({
            isExecTrusteeApprovalRoleWiseLoading: true,
            error,
          });
        }
      );
  };

  getExecTrusteeApplicationCountsList = () => {
    const transformData = (data) =>
      Object.entries(data).map((o, idx) => ({
        id: idx + 1,
        is_active: "1",
        status: `${o[0]} (${o[1]})`,
      }));
    fetch(`${getSLIM4APIUrl()}exec-trustee/getApplicationCounts  `, {
      method: "GET",
      headers: {
        Authorization: `Basic ${this.getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
        } else {
          if (result) {
            this.getExecTrusteeApprovalRoleWise(0, 1);
            this.setState({
              execTrusteeApplicationCountsList: transformData(result),
            });
          }
        }
        this.setState({
          isExecTrusteeApplicationCountsLoading: false,
        });
      });
  };

  handleSearch = (data, setSearchLoading) => {
    let paramsValue = getValueBeforeDashboardFromURL();
    let { search_by_its_id, search_by_application_id, search_by_name } = data;
    if (!(search_by_its_id || search_by_application_id || search_by_name)) {
      return false;
    }
    let searchParam = "";
    let searchUrl = "";
    if (search_by_its_id) {
      searchParam = search_by_its_id;
      searchUrl = "SearchItsID";
    } else {
      if (search_by_application_id) {
        searchUrl = "searchApllicationID";
        searchParam = search_by_application_id;
      } else {
        searchUrl = "searchName";
        searchParam = search_by_name;
      }
    }

    let apiUrl;
    if (paramsValue === "co") {
      apiUrl = getSLIM4APIUrl() + `coord/${searchUrl}/` + searchParam;
    } else if (paramsValue === "admin") {
      apiUrl = getSLIM4APIUrl() + `admin/${searchUrl}/` + searchParam;
    } else if (paramsValue === "aamilsaheb") {
      apiUrl = getSLIM4APIUrl() + `amilsaheb/${searchUrl}/` + searchParam;
    } else if (paramsValue === "counsellor") {
      apiUrl = getSLIM4APIUrl() + `counsellor/${searchUrl}/` + searchParam;
    } else if (paramsValue === "accounts") {
      apiUrl = getSLIM4APIUrl() + `accounts/${searchUrl}/` + searchParam;
    } else if (paramsValue === "trustee") {
      apiUrl = getSLIM4APIUrl() + `trustee/${searchUrl}/` + searchParam;
    } else if (paramsValue === "aamilsaheb") {
      apiUrl = getSLIM4APIUrl() + `amilsaheb/${searchUrl}/` + searchParam;
    }

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Basic ${localStorage.getItem("fa-token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (data.status == 200) {
          this.setState({
            ...this.state,
            searchedList: data.data,
          });
          setSearchLoading(false);
          data.data.length == 0 && toast.error("No search result found");
        } else {
          setSearchLoading(false);
          toast.error("No search result found");
        }
      })
      .catch((error) => {
        setSearchLoading(false);
        toast.error("No search result found");
      });
    this.setState({ ...this.state, searchedList: "" });
  };

  render() {
    if (this.state.redirectForNotAuthorized) {
      return <Redirect to="login" />;
    }
    //console.log(this.state.arazPreviewInfo)
    const { children } = this.props;
    const {
      hof_its,
      newArazLoader,
      cooDashBoardLoading,
      personalDetailsErrors,
      family_monthly_income,
      amilSahebComments,
      appCoordinators,
      isLoadedCounList,
      counsellorList,
      adminList,
      isLoadedTrusteeList,
      trusteeList,
      isLoadedETrusteeList,
      eTrusteeList,
      appStatusList,
      adminStatusList,
      isLoadedASL,
      isLoadedAdminLoading,
      isLoadedAppList,
      appList,
      appStatusHistory,
      isLoadedAppStatus,
      isLoadedAppChat,
      chatHistory,
      countFiles,
      docsApprove,
      requiredFiles,
      isLoadedDocs,
      track_id,
      arazListAsRoleWise,
      isLoadedALR,
      applicationInfo,
      tanzeemNewArazList,
      isLoadedTNA,
      tanzeemNewArazHistoryList,
      isLoadedTNAH,
      fs_edu_total_cost,
      isLoadedPI,
      isLoadedAC,
      isLoadedFS,
      isLoadedAP,
      step,
      personalDetails,
      preEduFinancial,
      familyMember,
      eduDetails,
      entranceExam,
      achivementsSchoolCollege,
      courseDetails,
      feesStructure,
      countries,
      cities,
      ptay_grd_country_list,
      ptay_10th_country_list,
      ptay_12th_country_list,
      arazPreviewInfo,
      previewArazUserInfo,
      submittedPersonalDetails,
      submittedPreEduFinancial,
      submittedFamilyMember,
      submittedEduDetails,
      submittedEntranceExam,
      submittedAchivementsSchoolCollege,
      isTrusteeListAsRoleWiseLoading,
      trusteeListAsRoleWise,
      isExecTrusteeApprovalRoleWiseLoading,
      execTrusteeApprovalRoleWiseList,
      trusteeApprovalSecondApprovalList,
      execTrusteeApplicationCountsList,
      isExecTrusteeApplicationCountsLoading,
      trusteeApplicationCountsList,
      isTrusteeApplicationCountsLoading,
      execTrusteeApplicationInfo,
      searchedList,
      searchLoading,
      counselorApprovalList,
      isCounselorApprovalCountsLoading,
      counselorListAsRoleWise,
      isCounselorListAsRoleWiseLoading,
      counselorApplicationCountsList,
      counsellorApplicationInfo,
      showListWise,
      // redirectToReferrer,
      // role,
      coList,
    } = this.state;
    const {
      refreshArazList,
      setArazPreviewInfo,
      getPreEduFinancial,
      setPersonalDetailsErrors,
      getAchivementSchoolCollege,
      getEntranceExamsDetails,
      pushPreEduFinancial,
      setStep,
      deleteEntanceExamField,
      deleteAchivementsSchoolCollegeField,
      deleteFeesStructureField,
      deleteEduFinancialField,
      addEntanceExamField,
      addAchivementsSchoolCollegeField,
      addFeesStructureField,
      addEduFinancialField,
      assignApp,
      getTrusteeList,
      getETrusteeList,
      verifyDetailByCoOrdinators,
      getCounsellorList,
      getAppStatusList,
      getAppList,
      getAdminListAsRoleWise,
      getAdminStatusList,
      getAppStatusHistory,
      addAppChatHistory,
      getAppChatHistory,
      submitFinalApproval,
      setApplicationDocumentCheckBox,
      getRequiredFiles,
      approveFaFees,
      setApplicationDocsCheckBox,
      setStates,
      getTrackIdFromAppId,
      approveFaPersonal,
      approveFaAcademic,
      approveFaCover,
      setApplicationCheckBox,
      getApplicationDetailsByAppId,
      getArazListAsRoleWise,
      getTanzeemWiseAraz,
      getTanzeemWiseArazHistory,
      getApplicationInfo,
      setApplicationInfo,
      appliedAmount,
      submitFinalAraz,
      setPersonalDetails,
      setPreEduFinancial,
      setFamilyMember,
      sendFaPersonal,
      sendFaAcademic,
      sendFaCover,
      getApplicationDetails,
      getAcademicDetails,
      getCountryList,
      getCityList,
      setEduDetails,
      setEntranceExam,
      setAchivementsSchoolCollege,
      setFeesStructure,
      setCourseDetails,
      getCourseFeesDetails,
      toMonthName,
      getArazInfoForPreview,
      getArazPreviewData,
      submittedArazLoader,
      getCookie,
      setToken,
      getToken,
      deleteApp,
      // getTrusteeApprovalRoleWise,
      // getTrusteeApplicationCountsList,
      getExecTrusteeApprovalRoleWise,
      getExecTrusteeApplicationCountsList,
      getExecTrusteeApplicationInfo,
      handleSearch,
      getCounselorApprovalList,
      getCounselorApprovalRoleWise,
      getCounselorApplicationCountsList,
      getCounsellorApplicationInfo,
      setShowListWise,
      getSLIM4APIUrl,
    } = this;

    return (
      <Context.Provider
        value={{
          hof_its,
          refreshArazList,
          cooDashBoardLoading,
          setArazPreviewInfo,
          newArazLoader,
          getPreEduFinancial,
          setStep,
          getEntranceExamsDetails,
          personalDetailsErrors,
          setPersonalDetailsErrors,
          getAchivementSchoolCollege,
          amilSahebComments,
          family_monthly_income,
          appCoordinators,
          isLoadedTrusteeList,
          trusteeList,
          isLoadedETrusteeList,
          eTrusteeList,
          isLoadedCounList,
          counsellorList,
          adminList,
          appStatusList,
          adminStatusList,
          isLoadedASL,
          isLoadedAdminLoading,
          isLoadedAppList,
          appList,
          isLoadedAppStatus,
          isLoadedAppChat,
          countFiles,
          chatHistory,
          appStatusHistory,
          docsApprove,
          requiredFiles,
          isLoadedDocs,
          track_id,
          getApplicationDetailsByAppId,
          arazListAsRoleWise,
          isLoadedALR,
          tanzeemNewArazList,
          tanzeemNewArazHistoryList,
          isLoadedTNAH,
          applicationInfo,
          isLoadedTNA,
          fs_edu_total_cost,
          step,
          personalDetails,
          preEduFinancial,
          familyMember,
          eduDetails,
          entranceExam,
          achivementsSchoolCollege,
          courseDetails,
          feesStructure,
          countries,
          cities,
          ptay_grd_country_list,
          ptay_10th_country_list,
          ptay_12th_country_list,
          arazPreviewInfo,
          previewArazUserInfo,
          submittedPersonalDetails,
          submittedPreEduFinancial,
          submittedFamilyMember,
          submittedEduDetails,
          submittedEntranceExam,
          submittedAchivementsSchoolCollege,
          submittedArazLoader,
          approveFaCover,
          getArazPreviewData,
          isLoadedPI,
          isLoadedAC,
          isLoadedFS,
          isLoadedAP,
          isTrusteeListAsRoleWiseLoading,
          trusteeListAsRoleWise,
          isExecTrusteeApprovalRoleWiseLoading,
          execTrusteeApprovalRoleWiseList,
          trusteeApprovalApprovalList: this.state.trusteeApprovalApprovalList,
          trusteeApprovalSecondApprovalList,
          execTrusteeApplicationCountsList,
          isExecTrusteeApplicationCountsLoading,
          trusteeApplicationCountsList,
          isTrusteeApplicationCountsLoading,
          execTrusteeApplicationInfo,
          searchedList,
          searchLoading,
          counselorApprovalList,
          isCounselorApprovalCountsLoading,
          counselorListAsRoleWise,
          isCounselorListAsRoleWiseLoading,
          counselorApplicationCountsList,
          counsellorApplicationInfo,
          deleteEntanceExamField,
          deleteAchivementsSchoolCollegeField,
          deleteFeesStructureField,
          deleteEduFinancialField,
          addEntanceExamField,
          addAchivementsSchoolCollegeField,
          addFeesStructureField,
          addEduFinancialField,
          assignApp,
          getTrusteeList,
          getETrusteeList,
          verifyDetailByCoOrdinators,
          getCounsellorList,
          getAppStatusList,
          getAdminListAsRoleWise,
          getAdminStatusList,
          getAppList,
          getAppStatusHistory,
          addAppChatHistory,
          getAppChatHistory,
          submitFinalApproval,
          setApplicationDocumentCheckBox,
          getRequiredFiles,
          approveFaFees,
          setApplicationDocsCheckBox,
          setStates,
          getTrackIdFromAppId,
          setApplicationCheckBox,
          getArazListAsRoleWise,
          getApplicationInfo,
          setApplicationInfo,
          getTanzeemWiseArazHistory,
          getTanzeemWiseAraz,
          appliedAmount,
          setPersonalDetails,
          setPreEduFinancial,
          setFamilyMember,
          sendFaPersonal,
          sendFaAcademic,
          sendFaCover,
          approveFaPersonal,
          approveFaAcademic,
          getApplicationDetails,
          getAcademicDetails,
          getCountryList,
          getCityList,
          setEduDetails,
          setEntranceExam,
          setAchivementsSchoolCollege,
          setFeesStructure,
          setCourseDetails,
          getCourseFeesDetails,
          toMonthName,
          getArazInfoForPreview,
          submitFinalAraz,
          getCookie,
          setToken,
          getToken,
          deleteApp,
          pushPreEduFinancial,
          // getTrusteeApprovalRoleWise,
          // getTrusteeApplicationCountsList,
          getExecTrusteeApprovalRoleWise,
          getExecTrusteeApplicationCountsList,
          getExecTrusteeApplicationInfo,
          handleSearch,
          getCounselorApprovalList,
          getCounselorApprovalRoleWise,
          getCounselorApplicationCountsList,
          getCounsellorApplicationInfo,
          setShowListWise,
          showListWise,
          coList,
          getSLIM4APIUrl,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
export { ContextProvider, Context };
