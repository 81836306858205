import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { Loader } from "react-loaders";
import PropTypes from "prop-types";

const BlockLoader = ({ loading, children }) => {
  const styles = {
    overlay: (base) => ({
      ...base,
      background: "rgba(255,255,255,0.5)",
    }),
  };
  return (
    <LoadingOverlay
      styles={styles}
      active={loading}
      spinner={<Loader active type="ball-pulse" />}
    >
      {children}
    </LoadingOverlay>
  );
};

BlockLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default BlockLoader;
